<template>
  <TextPage :html="termsOfUse" />
</template>

<script>
import termsOfUse from './terms-of-use.md';
import TextPage from '../text-page/text-page.vue';

export default {
  name: 'TermsOfUse',
  components: { TextPage },
  computed: {
    termsOfUse() {
      return termsOfUse;
    },
  },
};
</script>
